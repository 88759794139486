var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNavBarVisible),expression:"isNavBarVisible"}],staticClass:"navbar is-fixed-top",attrs:{"id":"navbar-main"}},[_c('div',{staticClass:"navbar-brand"},[_c('a',{staticClass:"navbar-item is-hidden-desktop",on:{"click":function($event){$event.preventDefault();return _vm.menuToggleMobile($event)}}},[_c('b-icon',{attrs:{"icon":_vm.menuToggleMobileIcon}})],1),_c('div',{staticClass:"navbar-item has-control no-left-space-touch"},[_c('div',{staticClass:"control"},[_c('img',{style:({
            height: '3rem',
            'max-height': '3rem',
            float: 'left'
          }),attrs:{"src":_vm.logo}}),_c('h1',{style:({
            'white-space': 'nowrap',
            float: 'left',
            'margin-top': '4px',
            'margin-left': '12.5px',
            'font-size': '1.5rem'
          })},[_vm._v("Dojnaz Coupons")])])])]),_c('div',{staticClass:"navbar-brand is-right"},[_c('a',{staticClass:"navbar-item navbar-item-menu-toggle is-hidden-desktop",on:{"click":function($event){$event.preventDefault();return _vm.menuNavBarToggle($event)}}},[_c('b-icon',{attrs:{"icon":_vm.menuNavBarToggleIcon,"custom-size":"default"}})],1)]),_c('div',{staticClass:"navbar-menu fadeIn animated faster",class:{'is-active':_vm.isMenuNavBarActive}},[_c('div',{staticClass:"navbar-end"},[_c('nav-bar-menu',{staticClass:"has-divider has-user-avatar"},[_c('user-avatar'),_c('div',{staticClass:"is-user-name"},[_c('span',[_vm._v(_vm._s(_vm.userName))])]),_c('div',{staticClass:"navbar-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/profile","exact-active-class":"is-active"}},[_c('b-icon',{attrs:{"icon":"account","custom-size":"default"}}),_c('span',[_vm._v("My Profile")])],1),_c('a',{staticClass:"navbar-item"},[_c('b-icon',{attrs:{"icon":"settings","custom-size":"default"}}),_c('span',[_vm._v("Settings")])],1),_c('a',{staticClass:"navbar-item"},[_c('b-icon',{attrs:{"icon":"email","custom-size":"default"}}),_c('span',[_vm._v("Messages")])],1),_c('hr',{staticClass:"navbar-divider"}),_c('a',{staticClass:"navbar-item"},[_c('b-icon',{attrs:{"icon":"logout","custom-size":"default"}}),_c('span',[_vm._v("Log Out")])],1)],1)],1),_c('a',{staticClass:"navbar-item is-desktop-icon-only",attrs:{"title":"Log out"},on:{"click":_vm.logout}},[_c('b-icon',{attrs:{"icon":"logout","custom-size":"default"}}),_c('span',[_vm._v("Log out")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }